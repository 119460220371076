import PTooltip from 'primevue/tooltip'
import { TOOLTIP_SHOW_DELAY } from '@/constants'
import { type TooltipDirectivePassThroughOptions } from 'primevue/tooltip'
type DTooltip = typeof PTooltip & {
    extend: (name: string, props: Omit<DTooltip, 'extend'>) => DTooltip
}

// https://github.com/primefaces/primevue-tailwind/blob/0.9.0/presets/aura/tooltip/index.js
const tooltipPassThrough: TooltipDirectivePassThroughOptions = {
    root: ({ context }) => ({
        class: [
            'absolute max-w-[420px] shadow m-0 p-0 !z-tooltip rounded',
            {
                'ml-2': context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                '-ml-2': context?.left,
                '-mt-2': context?.top,
                'mt-2': context?.bottom,
            },
        ],
    }),
    text: {
        class: 'text-xsmall break-words rounded bg-slate-100 p-1 text-slate-700',
    },
    arrow: ({ context }) => ({
        class: [
            'absolute w-0 h-0 border-transparent border-solid',
            {
                '-ml-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-slate-100':
                    context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                '-mr-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-slate-100': context?.left,
                '-mb-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-slate-100': context?.top,
                '-mt-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-slate-100': context?.bottom,
            },

            // Spacing
            {
                '-mt-[4px] top-1/2':
                    context?.right ||
                    context?.left ||
                    (!context?.right && !context?.left && !context?.top && !context?.bottom),
                '-ml-[4px] left-1/2': context?.top || context?.bottom,
            },
        ],
    }),
}

const Tooltip = (PTooltip as DTooltip).extend('dtooltip', {
    created(el, binding) {
        const { value } = binding
        const val = typeof value === 'string' ? { value } : value
        binding.value = {
            showDelay: TOOLTIP_SHOW_DELAY,
            unstyled: true,
            pt: tooltipPassThrough,
            ...val,
        }
    },
    beforeUpdate(el, binding) {
        const { value } = binding
        const val = typeof value === 'string' ? { value } : value
        binding.value = {
            showDelay: TOOLTIP_SHOW_DELAY,
            unstyled: true,
            pt: tooltipPassThrough,
            ...val,
        }
    },
})

export default Tooltip
