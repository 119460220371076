import { client } from '@/plugins/trpc'
import type { RouterOutput } from '@/types'
import { useQuery, useMutation } from '@/composables/vue-query-with-magic'
import { toValue, type MaybeRef, type Ref } from 'vue'
import { useCustomToast } from '@/composables/toast'
import { useI18n } from 'vue-i18n'

export type FavoriteSavedView = RouterOutput['savedView']['listFavorites'][number]

export function useSavedView() {
    const { showSuccessToast } = useCustomToast()
    const { t } = useI18n()
    return {
        initPersistedView(id: Ref<string>, force: Ref<boolean>) {
            return useQuery({
                queryKey: ['initPersistedView', id],
                queryFn: () => client.savedView.initPersistedView.query({ parentId: id.value, force: force.value }),
                queries: [],
                refetchOnWindowFocus: 'always',
                gcTime: 0,
            })
        },
        list() {
            return useQuery({
                queryKey: ['savedViews'],
                queryFn: () => client.savedView.list.query(),
                queries: ['EntityView'],
                gcTime: 0,
            })
        },
        listFavorites() {
            return useQuery({
                queryKey: ['savedViewsFavorites'],
                queryFn: () => client.savedView.listFavorites.query(),
                queries: ['EntityView'],
                initialData: [],
                gcTime: 0,
            })
        },
        byId(id: MaybeRef<string>, enabled: Ref<boolean>) {
            return useQuery({
                queryKey: ['entityViewById', id],
                queryFn: () => client.savedView.byId.query({ id: toValue(id) }),
                queries: ['EntityView'],
                enabled,
                // The placeholderData function ensures that the query doesn't return no data when refetching
                placeholderData: (previousValue) => previousValue,
            })
        },
        create() {
            return useMutation({
                mutationFn: client.savedView.create.mutate,
                mutates: ['EntityView'],
            })
        },
        update() {
            return useMutation({
                mutationFn: client.savedView.update.mutate,
                mutates: ['EntityView'],
            })
        },
        updateVisibility() {
            return useMutation({
                mutationFn: client.savedView.update.mutate,
                mutates: ['EntityView'],
                onSuccess: ({ visibility }) => {
                    if (visibility === 'public') {
                        showSuccessToast(t('savedViews.toast.successPublic'))
                    } else {
                        showSuccessToast(t('savedViews.toast.successPrivate'))
                    }
                },
            })
        },
        updateIsFavorite() {
            return useMutation({
                mutationFn: client.savedView.updateIsFavorite.mutate,
                mutates: ['EntityView'],
            })
        },
        reorderFavorites() {
            return useMutation({
                mutationFn: client.savedView.reorderFavorites.mutate,
                mutates: ['EntityView'],
            })
        },
        remove() {
            return useMutation({
                mutationFn: client.savedView.remove.mutate,
                mutates: ['EntityView'],
                onSuccess: () => {
                    showSuccessToast(t('savedViews.toast.successDelete'))
                },
            })
        },
        savePersistedView() {
            return useMutation({
                mutationFn: client.savedView.savePersistedView.mutate,
                mutates: [],
            })
        },
        getIdByPageName(pageName: string, enabled: Ref<boolean>) {
            return useQuery({
                queryKey: ['entityViewIdByPageName', pageName],
                queryFn: () => client.savedView.getIdByPageName.query({ pageName }),
                queries: ['EntityView'],
                enabled,
            })
        },
    }
}
