import mitt from 'mitt'
import type { Person } from '@prisma/client'

export type MentionPersonsPayload = {
    persons: Pick<Person, 'id' | 'name'>[]
}

// Define global events and their payloads
export type GlobalEvents = {
    'send-message-to-members': MentionPersonsPayload
}

export const eventBus = mitt<GlobalEvents>()

export default eventBus
