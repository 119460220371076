<script lang="ts" setup>
import { ref, computed, watch } from 'vue'

import type { MenuItem } from 'primevue/menuitem'
import type { TieredMenuPassThroughOptions } from 'primevue/tieredmenu'
import type { PassThrough } from '@primevue/core'
import type { Size } from '@/composables/sizing'
import type { DButtonType } from './DButton.vue'

import DIcon from './DIcon.vue'
import DCheckbox from './DCheckbox.vue'
import DAvatar from './DAvatar.vue'
import DButton from './DButton.vue'

export interface DMenuItem extends MenuItem {
    items?: DMenuItem[]
    value?: string
    groupName?: string
    group?: string
    selectMode?: 'single' | 'multi'
    icon?: string
    iconClass?: string
    itemClass?: string
    labelClass?: string
    helperText?: string
    testid?: string
    command?: () => void
}

export interface Props {
    modelValue?: DMenuItem | DMenuItem[]
    menuItems: DMenuItem[]
    /**
     * NOTE: The 'popup' prop has been introduced to enable the functionality of opening the action menu within Storybook.
     * probably it won't be used in real life example.
     */
    popup?: boolean
    icon?: string
    iconRight?: string
    size?: Size
    label?: string
    testid?: string
    disabled?: boolean
    type?: DButtonType
}

const props = withDefaults(defineProps<Props>(), {
    menuItems: () => [],
    popup: true,
    icon: 'dots-vertical',
    type: 'ghost',
})

const emit = defineEmits<{
    (name: 'update:modelValue', value?: DMenuItem[] | DMenuItem | undefined): void
    (name: 'toggle-menu', event: Event): void
    (name: 'show'): void
    (name: 'hide'): void
}>()

const modelValueMappedToArray = computed(() =>
    Array.isArray(props.modelValue) ? props.modelValue : props.modelValue ? [props.modelValue] : []
)

const selectedValues = ref<DMenuItem[]>(modelValueMappedToArray.value || [])

watch(modelValueMappedToArray, (newValue) => {
    selectedValues.value = newValue
})

const nestedMenuItemsCounter = computed(() => {
    return selectedValues?.value.reduce((groups: { [key: string]: number }, item) => {
        if (item?.groupName) {
            groups[item.groupName] = groups[item.groupName] ? groups[item.groupName] + 1 : 1
        }
        return groups
    }, {})
})

function clickItem(clickedItem: DMenuItem, event: Event, hasSubmenu: boolean) {
    if (clickedItem.disabled) return

    if (!hasSubmenu && clickedItem.selectMode === 'multi') {
        event.stopPropagation()
        clickedItem?.command && clickedItem.command()
    }

    if (clickedItem.value) {
        if (clickedItem.selectMode === 'multi') {
            const index = selectedValues.value.findIndex((item) => item?.value === clickedItem.value)
            if (index > -1) {
                selectedValues.value.splice(index, 1)
            } else {
                selectedValues.value = [...selectedValues.value, clickedItem]
            }
            emit('update:modelValue', selectedValues.value)
        } else {
            const index = selectedValues.value.findIndex((item) => item?.groupName === clickedItem.groupName)
            if (index === -1) {
                selectedValues.value = [...selectedValues.value, clickedItem]
            } else {
                selectedValues.value.splice(index, 1, clickedItem)
            }
            emit('update:modelValue', selectedValues.value[0])
        }
    }
}

const isItemSelected = (value: unknown) => !!selectedValues.value.some((selectedItem) => selectedItem?.value === value)

// Tiered Menu pass through documentation: https://v3.primevue.org/tieredmenu/#pt
const DActionMenuPassThrough: PassThrough<TieredMenuPassThroughOptions> = {
    root: () => ({
        class: 'border-none shadow-none bg-transparent mt-0.5 py-[3.5px] min-w-44 max-h-[450px] relative',
    }),
    rootList: () => ({
        class: 'p-0 shadow-md bg-white rounded max-w-md outline-none max-h-[450px] overflow-x-auto',
    }),
    submenu: () => ({
        class: 'absolute z-10 top-0 left-full min-w-full p-0 shadow-md bg-white rounded max-w-md flex flex-col max-h-[450px] overflow-x-auto',
    }),
    item: ({ context }) => ({
        class: ['static pb-1.5 first:pt-2 last:pb-2 px-1', { 'opacity-60': context.disabled }],
    }),
    itemContent: ({ context }) => ({
        class: [
            'max-w-md',
            context.active ? 'bg-slate-100' : 'bg-white',
            { 'hover:bg-slate-100 hover:rounded': !context.disabled },
        ],
    }),
    separator: () => ({
        class: 'm-0 mb-1.5 border-gray-200 border-t',
    }),
}

const menuRef = ref()
const toggleMenu = (event: Event) => {
    if (props.disabled) return
    menuRef.value.toggle(event)
    emit('toggle-menu', event)
}
</script>
<template>
    <div
        aria-haspopup="true"
        :data-testid="testid"
        aria-controls="overlay-d-action-menu"
        @click.prevent="popup && toggleMenu($event)"
        @keyup.enter="popup && toggleMenu($event)"
    >
        <slot>
            <DButton :icon :label :disabled :type :data-testid="testid + '-trigger-button'" />
        </slot>
        <PTieredMenu
            id="overlay-d-action-menu"
            ref="menuRef"
            :model="menuItems"
            :pt="DActionMenuPassThrough"
            unstyled
            :data-testid="`${testid}-content`"
            :popup="popup"
            :exact="false"
            @show="emit('show')"
            @hide="emit('hide')"
        >
            <template #item="{ item, label: itemLabel, hasSubmenu }">
                <div
                    class="px-2 py-1 text-sm text-slate-700"
                    :class="[(item as DMenuItem)?.itemClass, { 'cursor-pointer': !item.disabled }]"
                    :data-testid="(item as DMenuItem)?.testid"
                    @click="clickItem(item as DMenuItem, $event, hasSubmenu)"
                >
                    <div class="flex items-center justify-between" :class="item.avatar ? 'min-h-6' : 'min-h-5'">
                        <!-- left side -->
                        <div class="flex items-center gap-1">
                            <DCheckbox
                                v-if="item?.selectMode === 'multi' && !hasSubmenu"
                                size="sm"
                                binary
                                :model-value="isItemSelected(item.value)"
                            />
                            <DAvatar
                                v-if="item.avatar"
                                size="md"
                                :users="item.avatar"
                                :selected-user-id="item.avatar.id"
                            />
                            <DIcon
                                v-if="item.icon"
                                :icon="item.icon"
                                size="md"
                                :class="item.iconClass ?? 'text-slate-500'"
                            />
                            <span :class="[item?.labelClass, 'whitespace-nowrap']">
                                {{ itemLabel }}
                            </span>
                        </div>

                        <!-- right side -->
                        <div class="flex translate-x-2 items-center">
                            <span v-if="hasSubmenu && item.group && item.selectMode === 'multi'" class="pr-1">
                                {{ nestedMenuItemsCounter[item.group] }}
                            </span>

                            <DIcon v-if="hasSubmenu" icon="chevron-right" class="text-slate-500" />

                            <DIcon
                                v-if="!hasSubmenu && item.selectMode === 'single' && isItemSelected(item.value)"
                                class="text-slate-500"
                                icon="check"
                            />
                        </div>
                    </div>
                    <span v-if="item.helperText" class="mt-1 text-xs text-slate-500">
                        {{ item.helperText }}
                    </span>
                </div>
            </template>
        </PTieredMenu>
    </div>
</template>
