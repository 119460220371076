<script lang="ts" setup>
import { computed } from 'vue'
import DIcon from './DIcon.vue'
import DButton from './DButton.vue'

/**
 * DHeaderBarVModel defines if the left and right buttons are in expanded state (=open) or not.
 */
export type DHeaderBarVModel = {
    iconLeftExpanded?: boolean
    iconRightExpanded?: boolean
}

withDefaults(
    defineProps<{
        showLeftIcon?: boolean
        showRightIcon?: boolean
        hasBorder?: boolean
        showLineageIcon?: boolean
        entityId?: string
        rightIconTooltip?: string
        rightIconDisabled?: boolean
    }>(),
    {
        showLeftIcon: true,
        showRightIcon: false,
        hasBorder: true,
        showLineageIcon: false,
        rightIconDisabled: false,
    }
)

const model = defineModel<DHeaderBarVModel>({
    default: () => ({
        iconLeftExpanded: true,
        iconRightExpanded: true,
    }),
})

const onIconLeftToggle = () => {
    model.value = {
        ...model.value,
        iconLeftExpanded: !model.value.iconLeftExpanded,
    }
    // non-null assertion is safe here because the value is set just above
    emit('icon-left-toggle', model.value.iconLeftExpanded!)
}

const onIconRightToggle = () => {
    model.value = {
        ...model.value,
        iconRightExpanded: !model.value.iconRightExpanded,
    }
    // non-null assertion is safe here because the value is set just above
    emit('icon-right-toggle', model.value.iconRightExpanded!)
}

const iconSidebarLeft = computed(() =>
    model.value.iconLeftExpanded ? 'layout-sidebar-left-collapse' : 'layout-sidebar-left-expand'
)

const iconSidebarRight = computed(() =>
    model.value.iconRightExpanded ? 'layout-sidebar-right-collapse' : 'layout-sidebar-right-expand'
)

const emit = defineEmits<{
    (name: 'icon-left-toggle', value: boolean): void
    (name: 'icon-right-toggle', value: boolean): void
}>()
</script>
<template>
    <div role="heading" class="min-h-fit bg-white p-2" :class="{ 'border-b border-slate-300': hasBorder }">
        <div class="flex w-full items-start gap-x-1">
            <DIcon
                v-if="showLeftIcon"
                v-tooltip.right="model.iconLeftExpanded ? $t('collapseMenu') : $t('expandMenu')"
                class="cursor-pointer p-0.5 text-slate-500 hover:text-slate-700"
                :icon="iconSidebarLeft"
                size="lg"
                :stroke="1.5"
                @click="onIconLeftToggle"
            />
            <slot />

            <template v-if="showLineageIcon">
                <RouterLink :to="{ name: 'business-lineage-full-screen', params: { id: entityId } }">
                    <DButton
                        v-tooltip.left="$t('businessLineage.title')"
                        type="common"
                        icon="route-alt-right"
                        size="md"
                        class="text-slate-500"
                        :icon-stroke="1.5"
                        data-testid="open-business-lineage"
                    />
                </RouterLink>
                <div id="spacer" class="border-l border-slate-200 w-0 h-6 ml-2" />
            </template>

            <DIcon
                v-if="showRightIcon"
                v-tooltip.left="
                    rightIconTooltip || (model.iconRightExpanded ? $t('collapseDetails') : $t('expandDetails'))
                "
                :class="[
                    'p-0.5',
                    {
                        'cursor-pointer text-slate-500 hover:text-slate-700': !rightIconDisabled,
                        'text-slate-400': rightIconDisabled,
                    },
                ]"
                :icon="iconSidebarRight"
                disabled
                size="lg"
                :stroke="1.5"
                @click="() => !rightIconDisabled && onIconRightToggle()"
            />
        </div>
    </div>
</template>
