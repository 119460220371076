<script lang="ts" setup>
import { ref, computed, useTemplateRef } from 'vue'
import type { Person, Entity } from '@prisma/client'
import { v4 as uuidv4 } from 'uuid'
import { type SelectorItem } from '@/composables/selector'

import DIcon from '@/components/DIcon.vue'
import DAvatar from '@/components/DAvatar.vue'
import DButton, { type DButtonType } from '@/components/DButton.vue'
import DTooltip from '@/components/DTooltip.vue'
import DSelectorGeneric from '@/components/DSelectorGeneric.vue'
import DCommsMenu from '@/components/DCommsMenu.vue'

const props = defineProps<{
    modelValue?: string[]
    people?: (Pick<Person, 'id' | 'auth0Id' | 'name' | 'photoUrl' | 'email'> & {
        disabled?: boolean
        disabledText?: string
    })[]
    placeholder?: string
    filterPlaceholder?: string
    selectedLabel?: string
    buttonType?: DButtonType
    showTooltip?: boolean
    withCommsMenu?: boolean
    entity?: Pick<Entity, 'label' | 'id'> | undefined
}>()

const peopleSelectorId = `peopleSelector-${uuidv4()}`
const maxNumberOfPeopleInTooltip = 10

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string[]): void
    (name: 'select', value: string): void
    (name: 'unselect', value: string): void
}>()

const singlePersonName = useTemplateRef('singlePersonName')
const peopleTooltip = useTemplateRef('peopleTooltip')
const isSelectorOpened = ref(false)

const options = computed(() => {
    const mappedItems =
        props.people?.map((person) => ({
            id: person.id,
            auth0Id: person.auth0Id,
            name: person.name,
            label: person.name,
            email: person.email,
            avatar: {
                id: person.id,
                photoUrl: person.photoUrl,
            },
            disabled: person.disabled,
            disabledText: person.disabledText,
        })) ?? []
    return mappedItems
})

const selectedPeople = computed(() => options.value.filter(({ id }) => props.modelValue?.includes(id)))

const selectedPeopleAvatars = computed(() => {
    return selectedPeople.value.map((person) => ({
        id: person.avatar?.id,
        photoUrl: person.avatar?.photoUrl,
    }))
})

function onModelValueUpdate(items: SelectorItem[]) {
    const modelValue = items.map((item) => item.id)
    emit('update:modelValue', modelValue)
}

const shouldAddCommsMenu = computed(() => {
    return props.withCommsMenu && selectedPeople.value?.length
})
</script>

<template>
    <div :class="{ 'flex justify-between items-center overflow-hidden': shouldAddCommsMenu }">
        <div :class="{ 'flex-1 w-10': shouldAddCommsMenu }">
            <DSelectorGeneric
                :model-value="selectedPeople"
                :options="options"
                multiple
                max-width="sm"
                :filter-placeholder="filterPlaceholder"
                :no-items-label="$t('components.SelectorItem.noPersonsFound')"
                @show="isSelectorOpened = true"
                @hide="isSelectorOpened = false"
                @select-item="emit('select', $event)"
                @unselect-item="emit('unselect', $event)"
                @update:model-value="onModelValueUpdate"
            >
                <slot>
                    <DButton
                        :id="peopleSelectorId"
                        :type="buttonType ?? 'ghost'"
                        @mouseenter="peopleTooltip?.show"
                        @mouseleave="peopleTooltip?.hide"
                    >
                        <template v-if="selectedPeople?.length">
                            <div class="flex gap-x-1 overflow-hidden">
                                <DAvatar
                                    v-if="selectedPeople.length"
                                    :users="selectedPeopleAvatars"
                                    size="sm"
                                    :max="3"
                                />
                                <span v-if="selectedPeople.length === 1" ref="singlePersonName" class="truncate">
                                    {{ selectedPeople[0]?.label }}
                                </span>
                                <span v-else-if="selectedPeople.length > 1" class="truncate">
                                    {{ selectedPeople.length }} {{ selectedLabel }}
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <DIcon icon="users-group" class="text-slate-500" size="md" />
                            <span class="truncate">{{ placeholder }}</span>
                        </template>
                    </DButton>

                    <DTooltip
                        v-if="showTooltip && selectedPeople?.length"
                        ref="peopleTooltip"
                        placement="bottom-end"
                        :anchor="peopleSelectorId"
                    >
                        <div class="grid grid-cols-1 gap-2 p-1">
                            <div
                                v-for="person in selectedPeople.slice(0, maxNumberOfPeopleInTooltip)"
                                :key="person?.id"
                                class="flex items-center gap-x-2"
                            >
                                <DAvatar :users="[{ id: person?.id, photoUrl: person?.avatar?.photoUrl }]" size="xs" />
                                <span class="truncate">{{ person?.label }}</span>
                            </div>
                        </div>
                        <div class="ml-7">
                            <span v-if="selectedPeople.length > maxNumberOfPeopleInTooltip">
                                +{{ selectedPeople.length - maxNumberOfPeopleInTooltip }}
                            </span>
                        </div>
                    </DTooltip>
                </slot>
            </DSelectorGeneric>
        </div>
        <DCommsMenu v-if="shouldAddCommsMenu && entity" :people="selectedPeople" :entity />
    </div>
</template>
