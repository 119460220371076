<script lang="ts" setup>
import { computed, onMounted, onUpdated, ref, useTemplateRef } from 'vue'
import DTag from './DTag.vue'
import { useResizeObserver } from '@vueuse/core'
import DButton from './DButton.vue'

const props = withDefaults(
    defineProps<{
        tags: {
            id: string
            label?: string
            value?: string
        }[]
        borderStyle?: 'rounded' | 'square'
        font?: 'primary' | 'secondary'
        withShowMore?: boolean
        readonly?: boolean
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left'
        emptyText?: string
    }>(),
    {
        borderStyle: 'rounded',
        font: 'primary',
        withShowMore: false,
        tooltipPosition: 'bottom',
        readonly: true,
    }
)

const containerElement = useTemplateRef('containerElement')
const hiddenTagsCounters = ref<number>(0)
const counterPositionLeft = ref()
const counterPositionTop = ref()
const counterTag = useTemplateRef('counterTag')

const counterTagWidth = ref<number>()
function calculateCounterTagWidth() {
    if (!counterTag.value) return
    const tag = counterTag.value?.querySelector(':scope > span')
    if (!tag) return
    counterTagWidth.value = tag?.getBoundingClientRect()?.width
}

const chromeFirstLineOffsetTop = 1
const chromeSecondLineOffsetTop = 24
const firefoxFirstLineOffsetTop = 0
const firefoxSecondLineOffsetTop = 23
const availableOffsetsTopForVisibleTags = [
    chromeFirstLineOffsetTop,
    chromeSecondLineOffsetTop,
    firefoxFirstLineOffsetTop,
    firefoxSecondLineOffsetTop,
]

const hasOverflow = computed(() => {
    if (containerElement.value?.offsetHeight === undefined && containerElement.value?.scrollHeight === undefined)
        return false
    return containerElement.value?.offsetHeight < containerElement.value?.scrollHeight
})

function recalculateCounter() {
    calculateHiddenTags()
    calculateLastVisibleTagPosition()
}

onMounted(() => {
    recalculateCounter()
})

onUpdated(() => {
    recalculateCounter()
})

useResizeObserver(containerElement, () => {
    recalculateCounter()
})

function calculateHiddenTags() {
    const ref = containerElement.value
    if (!ref || !ref?.children) return

    const containerOffsetTop = ref.offsetTop
    const spans = ref.querySelectorAll(':scope > span')
    const tags = Array.from(spans) as HTMLElement[]
    const visibleTags = tags.filter((tag) => {
        // The tag.offsetTop value differs between the initial card initialization
        // and when the card is clicked. As a result, two separate checks are necessary.
        const whenCardsChanged = availableOffsetsTopForVisibleTags.includes(tag.offsetTop)
        const whenCardInitialized = Math.abs(tag.offsetTop - containerOffsetTop) === 1
        return whenCardsChanged || whenCardInitialized
    }).length

    const invisibleTags = ref.children.length - visibleTags
    hiddenTagsCounters.value = invisibleTags || 0

    calculateCounterTagWidth()
}

function calculateLastVisibleTagPosition() {
    const ref = containerElement.value
    if (!ref || !ref?.children) return

    const lastVisibleTagIndex = props.tags.length - hiddenTagsCounters.value - 1

    // We can't use ref.children because in firefox children have nodes other than HTML elements.
    const spans = ref.querySelectorAll(':scope > span')
    const tags = Array.from(spans) as HTMLElement[]
    const lastTag = tags[lastVisibleTagIndex]

    const gap = 4
    counterPositionLeft.value = lastTag?.offsetLeft + lastTag?.clientWidth + gap + 'px'
    counterPositionTop.value = lastTag?.offsetTop + 'px'
}

const counterText = computed(() => {
    if (hiddenTagsCounters.value > 99) return '+'
    return hiddenTagsCounters.value ? `+${hiddenTagsCounters.value}` : undefined
})

const tagsLabelsCommaSeparated = computed(() => {
    return props.tags.map((tag) => tag.value || tag.label).join(', ')
})

const tooltipPos = computed(() => ({
    position: props.tooltipPosition,
}))

const showMore = ref(false)
function onShowMore() {
    showMore.value = !showMore.value
    recalculateCounter()
}

const tagPaddingRight = computed(() => {
    return hiddenTagsCounters.value > 9 ? '37px' : '35px'
})
</script>
<template>
    <div>
        <div
            v-if="tags?.length"
            v-tooltip:[tooltipPos]="{ value: tagsLabelsCommaSeparated, escape: false }"
            class="relative text-xsmall"
            :class="[{ 'has-overflow': hasOverflow }]"
            :style="{ 'padding-right': hiddenTagsCounters && !showMore ? tagPaddingRight : 0 }"
        >
            <div ref="containerElement" class="gap-1 overflow-hidden flex flex-wrap" :class="{ 'max-h-11': !showMore }">
                <DTag
                    v-for="tag in tags"
                    :key="tag.id"
                    :label="tag.value || tag.label"
                    :border-style="borderStyle"
                    :readonly="readonly"
                    size="md"
                    :font="font"
                    class="inline-flex max-w-full"
                />
            </div>
            <div
                v-if="tags?.length && counterText && !showMore"
                ref="counterTag"
                class="absolute bottom-0"
                :style="{ left: counterPositionLeft, top: counterPositionTop }"
            >
                <DTag
                    :label="counterText"
                    size="md"
                    :border-style="borderStyle"
                    :font="font"
                    readonly
                    class="w-[27px] justify-center"
                />
            </div>
        </div>

        <div v-if="!tags?.length && emptyText" class="cursor-default text-sm">{{ emptyText }}</div>

        <DButton
            v-if="withShowMore && counterText"
            type="ghost"
            size="sm"
            class="mt-1 text-slate-700"
            @click.stop.prevent="onShowMore"
        >
            {{ showMore ? $t('showLess') : $t('showMore') }}
        </DButton>
    </div>
</template>
