<script lang="ts" setup>
import { ref } from 'vue'
import DIcon from '../DIcon.vue'
import LumoButton from './LumoButton.vue'

export interface LumoInlineMessageProps {
    title?: string
    message?: string
    button?: {
        text: string
        command: () => void
    }
    closable?: boolean
}

defineProps<LumoInlineMessageProps>()

const isVisible = ref(true)
</script>

<template>
    <div
        v-if="isVisible"
        class="flex relative justify-between items-start gap-x-2 w-full p-1 min-w-80 rounded min-h-8 bg-gradient-to-r from-teal-50 to-primarySky-50"
    >
        <div class="flex justify-between items-center w-full flex-wrap gap-x-2 gap-y-3">
            <div class="flex gap-x-3 items-center gap-y-2 flex-wrap min-h-6">
                <div class="flex gap-x-1">
                    <slot>
                        <LumoButton v-if="button" type="plain" @click="button.command()">
                            {{ button.text }}
                        </LumoButton>
                    </slot>
                </div>
                <div v-if="title" class="flex min-h-7 items-center">
                    <span class="text-small-medium">{{ title }}</span>
                </div>

                <div v-if="message" class="flex min-h-7 items-center">
                    <span class="text-small">{{ message }}</span>
                </div>
            </div>
        </div>

        <div v-if="closable" class="h-7 flex items-center">
            <DIcon icon="x" size="md" class="cursor-pointer" @click="isVisible = false" />
        </div>
    </div>
</template>
