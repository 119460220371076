<script lang="ts" setup>
import { computed } from 'vue'
import { EntityCoreTypeEnum } from '@prisma/client'
import { type SelectorItem } from '@/composables/selector'
import { useEntityTypes } from '@/composables/entityType'

import DSelectorGeneric from '@/components/DSelectorGeneric.vue'
import DIcon from '@/components/DIcon.vue'
import DButton from '@/components/DButton.vue'

const props = withDefaults(
    defineProps<{
        modelValue?: EntityCoreTypeEnum
        disabled?: boolean
    }>(),
    {
        disabled: false,
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: EntityCoreTypeEnum): void
    (name: 'select', value?: EntityCoreTypeEnum): void
}>()

type Option = SelectorItem & {
    id: EntityCoreTypeEnum
}

const { opportunityType, dataProductType } = useEntityTypes()
const options = computed<Option[]>(() => [
    {
        id: opportunityType.value.type,
        label: opportunityType.value.labelSingular,
        icon: opportunityType.value.icon,
    },
    {
        id: dataProductType.value.type,
        label: dataProductType.value.labelSingular,
        icon: dataProductType.value.icon,
    },
])
const selected = computed(() => options.value.filter((item) => item.id === props.modelValue))

function onModelUpdate(item?: SelectorItem[]) {
    if (!item) return
    emit('update:modelValue', item?.[0].id as EntityCoreTypeEnum)
}
</script>

<template>
    <span
        v-tooltip.bottom="{
            value: $t(`components.SelectorCoreEntityType.disabled.${modelValue}`),
            disabled: !disabled,
        }"
    >
        <DSelectorGeneric
            :disabled="disabled"
            :model-value="selected"
            :options="options"
            :filter="false"
            @update:model-value="onModelUpdate"
        >
            <DButton data-testid="d-selector-entity-type" :disabled="disabled">
                <DIcon v-if="selected[0]?.icon" :icon="selected[0]?.icon" class="text-slate-500" size="md" />
                {{ selected[0]?.label }}
            </DButton>
        </DSelectorGeneric>
    </span>
</template>
