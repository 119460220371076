<script lang="ts" setup>
import { EntityCoreTypeEnum } from '@prisma/client'
import { ref, computed, watch, useTemplateRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useForm, useField } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import type { Delta } from '@vueup/vue-quill'

import { useHeap } from '@/plugins/heap'
import { useCustomToast } from '@/composables/toast'
import { useEntityTypeByRoute, entityTypeToRoute } from '@/composables/router'
import type { TextEditorChangeEvent } from '@/composables/textEditor'
import type { DataProductPayload } from '@/composables/entityViewDataProduct'
import type { OpportunityPayload } from '@/composables/entityViewOpportunity'
import { useDataProductTypesForCustomProperty } from '@/composables/data/data-product-type'
import { useEntityTypes } from '@/composables/entityType'

import DInputText from '@/components/DInputText.vue'
import DTextEditor from '@/components/DTextEditor.vue'
import DButton from '@/components/DButton.vue'
import DModal from '@/components/DModal.vue'
import LumoBar from '@/components/LumoBar.vue'
import LumoButton from '@/components/lumo/LumoButton.vue'

import ActionBar from './ActionBar.vue'
import SelectorCoreEntityType from './SelectorCoreEntityType.vue'
import LumoActionMenu from '../lumo/LumoActionMenu.vue'

import { useSchema } from './schema'
import { useCreateEntityQueries } from './queries'

const props = withDefaults(
    defineProps<{
        defaultEntityCoreType?: EntityCoreTypeEnum
        disableEntityType?: boolean
        showTriggerButton?: boolean
    }>(),
    {
        disableEntityType: false,
        showTriggerButton: true,
    }
)

const { t } = useI18n()
const toast = useCustomToast()
const router = useRouter()
const { track } = useHeap()
const { opportunitySchema, dataProductSchema } = useSchema()
const { getEntityTypeByRoute } = useEntityTypeByRoute()
const entityQueries = useCreateEntityQueries()

const entityCoreType = ref(getDefaultEntityType())
const isOpportunity = computed(() => entityCoreType.value === EntityCoreTypeEnum.Opportunity)
const dataProductTypeId = ref<string | undefined>()

const validationSchema = computed(() => toTypedSchema(isOpportunity.value ? opportunitySchema : dataProductSchema))
const { handleSubmit, errors, setFieldValue, setErrors, resetForm } = useForm({
    validationSchema,
})

const { value: label, handleChange: handleChangeLabel } = useField<string>('label', undefined, { initialValue: '' })

const isVisible = ref(false)
const isSaveButtonLoading = ref(false)
const forceLabelError = ref(false)
const hasChanges = ref(false)
const actionBar = useTemplateRef('actionBar')
const descriptionEditor = useTemplateRef<typeof DTextEditor>('descriptionEditor')
const descriptionRef = ref<TextEditorChangeEvent | undefined>()
const defaultValues = ref<DataProductPayload | OpportunityPayload>({})

const customPropertyId = computed(() => defaultValues.value.customProperty?.propertyId)
const { dataProductTypeIdsEnabledForCustomProperty } = useDataProductTypesForCustomProperty(customPropertyId)
const { dataProductTypes, opportunityType, entityTypesMapById } = useEntityTypes()

const { mutateAsync: createOpportunity } = entityQueries.createOpportunity()
const { mutateAsync: createDataProduct } = entityQueries.createDataProduct()

defineExpose({ isVisible, show })

watch(entityCoreType, () => (forceLabelError.value = false))
watch(isOpportunity, () => setFormDefaults())
watch(descriptionRef, (event) => (hasChanges.value = !!event?.length))
watch(label, (newValue) => (hasChanges.value = !!newValue))
watch(forceLabelError, (isError) => {
    if (isError) setErrors({ label: t('lumo.labelErrorMessage') })
})

// Reset the form when the modal is opened and action bar instance is ready
watch([isVisible, actionBar], ([isVisible, actionBar]) => {
    if (!isVisible || !actionBar) return

    entityCoreType.value = getDefaultEntityType()
    descriptionRef.value = undefined
    resetForm()
    setFormDefaults()
})

function show(payload: DataProductPayload | OpportunityPayload = {}) {
    defaultValues.value = payload
    isVisible.value = true
}

function setFormDefaults() {
    dataProductTypeId.value = getDefaultDataProductTypeId()
    setFieldValue('phaseId', defaultValues.value.phaseId ?? '')
    setFieldValue('priority', defaultValues.value.priority ?? 'not_set')
    setFieldValue('ownerId', defaultValues.value.ownerId ?? '')
    setFieldValue('typeId', dataProductTypeId.value)
    setFieldValue('keywordIds', getDefaultArrayValue(defaultValues.value.keywordId))
    setFieldValue('businessTeamIds', getDefaultArrayValue(defaultValues.value.businessTeamId))
    setFieldValue('dataTeamIds', getDefaultArrayValue(defaultValues.value.dataTeamId))
    setFieldValue('stakeholderIds', getDefaultArrayValue(defaultValues.value.stakeholderId))
    if ('businessGoalId' in defaultValues.value || 'metricId' in defaultValues.value) {
        setFieldValue('businessGoalIds', getDefaultArrayValue(defaultValues.value.businessGoalId))
        setFieldValue('metricIds', getDefaultArrayValue(defaultValues.value.metricId))
    }
    if ('freeformCustomProperty' in defaultValues.value) {
        setFieldValue('freeformCustomProperty', defaultValues.value.freeformCustomProperty)
    }
    if (defaultValues.value.customProperty) {
        setFieldValue(
            'customProperties.0.valueIds',
            defaultValues.value.customProperty.valueId ? [defaultValues.value.customProperty.valueId] : []
        )
        setFieldValue('customProperties.0.id', defaultValues.value.customProperty.propertyId)
    } else {
        setFieldValue('customProperties', [])
    }
    actionBar.value?.resetFields()
    hasChanges.value = false
}

function getDefaultArrayValue(value: string | null | undefined): string[] | undefined {
    return value === null ? [] : value ? [value] : undefined
}

function getDefaultEntityType() {
    return props.defaultEntityCoreType ?? getEntityTypeByRoute()?.type ?? EntityCoreTypeEnum.Opportunity
}

function getDefaultDataProductTypeId(): string | undefined {
    if (isOpportunity.value) return
    if (customPropertyId.value) return dataProductTypeIdsEnabledForCustomProperty.value[0]
    if ('typeId' in defaultValues.value && defaultValues.value.typeId) return defaultValues.value.typeId

    const typeByRoute = getEntityTypeByRoute()
    const onNonEntityRoute = !typeByRoute
    const onOpportunityRoute = typeByRoute && typeByRoute?.type === EntityCoreTypeEnum.Opportunity
    const onDataProductsRoute = typeByRoute && !('id' in typeByRoute)
    if (onNonEntityRoute || onOpportunityRoute || onDataProductsRoute) return dataProductTypes.value[0].id

    // TS is complaining about `id` being unknown, but it isn't. `id` can be either string or missing, and
    // we are handling missing case above. So, we can safely cast it to string here.
    return typeByRoute.id as string
}

const onSubmit = handleSubmit(async (values) => {
    try {
        isSaveButtonLoading.value = true
        let entityId: string

        // TODO: refactor to type guard isOpportunity ONE-712
        if ('typeId' in values) {
            const rsp = await createDataProduct({
                ...values,
                description: descriptionRef.value?.current,
                priority: values.priority,
            })
            toast.showSuccessToast(t('components.CreateEntity.dataProduct.createSuccessToast'))
            entityId = rsp.id
            const entityType = entityTypesMapById.value[values.typeId]
            track('EntityCreated', {
                entityId,
                type: entityType.labelSingular,
            })
            router.push(entityTypeToRoute(entityType, entityId))
        } else {
            const opportunity = await createOpportunity({
                ...values,
                description: descriptionRef.value?.current,
                priority: values.priority,
            })
            toast.showSuccessToast(t('components.CreateEntity.opportunity.createSuccessToast'))
            entityId = opportunity.id
            track('EntityCreated', {
                entityId,
                type: opportunityType.value.labelSingular,
            })
            router.push(entityTypeToRoute(opportunityType, entityId))
        }
    } finally {
        isSaveButtonLoading.value = false
        isVisible.value = false
    }
})
</script>

<template>
    <div>
        <div v-if="showTriggerButton" data-testid="create-entity-button" @click="show()">
            <slot>
                <DButton
                    class="w-full"
                    :label="$t('components.CreateEntity.buttonLabel')"
                    icon="plus"
                    horizontal-alignment="left"
                />
            </slot>
        </div>

        <DModal
            data-testid="create-entity-modal"
            :is-visible="isVisible"
            :has-changes="hasChanges"
            :confirm-label="$t('components.CreateEntity.create')"
            confirm-button-submit
            form-name="createForm"
            :is-confirm-button-loading="isSaveButtonLoading"
            @update:is-visible="isVisible = $event"
            @cancel="forceLabelError = false"
            @confirm="forceLabelError = false"
        >
            <template #header>
                <div class="flex justify-between items-center w-full mr-3">
                    <SelectorCoreEntityType v-model="entityCoreType" :disabled="disableEntityType" />
                    <LumoBar
                        v-if="descriptionEditor"
                        :label="label"
                        :editor="descriptionEditor"
                        :type="isOpportunity ? EntityCoreTypeEnum.Opportunity : EntityCoreTypeEnum.DataProduct"
                        @trigger-label-error="forceLabelError = true"
                    >
                        <template #default="{ copilotBusy, lumoLabel, validateInputs, hasDescription }">
                            <LumoActionMenu
                                v-if="hasDescription"
                                :type="hasDescription || copilotBusy ? 'lumo' : 'plain'"
                                :loading="copilotBusy"
                                :label="lumoLabel"
                                data-testid="copilot-create-description-from-text"
                                @append="validateInputs({ shouldReplace: false })"
                                @replace="validateInputs({ shouldReplace: true })"
                            />
                            <LumoButton
                                v-else
                                type="lumo"
                                :label="lumoLabel"
                                :loading="copilotBusy"
                                data-testid="copilot-create-description-from-text"
                                @click="validateInputs({ shouldReplace: false })"
                            />
                        </template>
                    </LumoBar>
                </div>
            </template>

            <form id="createForm" @submit="onSubmit">
                <div class="mt-3 flex flex-col gap-3">
                    <DInputText
                        :model-value="label"
                        size="lg"
                        ghost
                        testid="label-input"
                        :placeholder="
                            isOpportunity
                                ? $t(`components.CreateEntity.opportunity.titlePlaceholder`)
                                : $t(`components.CreateEntity.dataProduct.titlePlaceholder`)
                        "
                        :error="errors.label"
                        @update:model-value="handleChangeLabel"
                    />
                    <DTextEditor
                        ref="descriptionEditor"
                        ghost
                        editor-id="create-description"
                        :delta="descriptionRef?.current as Delta"
                        :min-rows="5"
                        :max-rows="10"
                        :placeholder="
                            isOpportunity
                                ? $t(`components.CreateEntity.opportunity.descriptionPlaceholder`)
                                : $t(`components.CreateEntity.dataProduct.descriptionPlaceholder`)
                        "
                        data-testid="description-editor"
                        bounds-value="modal-content"
                        @change="descriptionRef = $event"
                    />

                    <ActionBar ref="actionBar" :entity-core-type="entityCoreType" />
                </div>
            </form>
        </DModal>
    </div>
</template>
