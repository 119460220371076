<script setup lang="ts">
import ViewLayout from './layouts/ViewLayout.vue'
import GlobalConfirmDialogsVue from './views/GlobalConfirmDialogs.vue'

import type { ToastPassThroughOptions } from 'primevue/toast'
import type { PassThrough } from '@primevue/core'

const toastDT = {
    detail: {
        font: {
            weight: 'normal',
        },
    },
    success: {
        background: '{emerald.50}',
        border: {
            color: '{emerald.500}',
        },
        detail: {
            color: '{emerald.500}',
        },
    },
    info: {
        background: '{violet.100}',
        border: {
            color: '{indigo.500}',
        },
        detail: {
            color: '{indigo.500}',
        },
    },
    warn: {
        background: '{orange-50}',
        border: {
            color: '{yellow-600}',
        },
        detail: {
            color: '{yellow-600}',
        },
    },
    error: {
        background: '{red.100}',
        border: {
            color: '{red.500}',
        },
        detail: {
            color: '{red.500}',
        },
    },
}

const toastPT: PassThrough<ToastPassThroughOptions> = {
    message: {
        class: '!border-r-0 !border-t-0 !border-b-0 !border-l-[6px]',
    },
}
</script>

<template>
    <ViewLayout>
        <RouterView />
    </ViewLayout>
    <PToast :auto-z-index="false" class="z-toast max-w-96" :dt="toastDT" :pt="toastPT" />
    <GlobalConfirmDialogsVue />
</template>
