<script lang="ts" setup>
import { computed } from 'vue'
import { type SelectorItem } from '@/composables/selector'
import DIcon from './DIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const props = withDefaults(
    defineProps<{
        modelValue?: string
        phases: SelectorItem[]
        buttonType?: DButtonType
        isLabel?: boolean
        testid?: string
        filterPlaceholder?: string
    }>(),
    {
        isLabel: true,
        testid: 'd-selector-phase',
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value: string): void
    (name: 'select', value: string): void
    (name: 'unselect'): void
}>()

const selected = computed(() => props.phases.filter(({ id }) => id === props.modelValue))

function onModelUpdate(items: SelectorItem[] | undefined) {
    if (!items) return
    emit('update:modelValue', items[0].id)
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="phases"
        max-width="sm"
        :filter-placeholder="filterPlaceholder"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelUpdate"
    >
        <DButton :type="buttonType ?? 'ghost'" :data-testid="testid">
            <DIcon v-if="selected[0]?.icon" :icon="selected[0]?.icon" :class="selected[0]?.iconColor" size="md" />
            <span v-if="isLabel" class="truncate">{{ selected[0]?.label }}</span>
        </DButton>
    </DSelectorGeneric>
</template>
