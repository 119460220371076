import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'
import type { Person } from '@prisma/client'
import type { SelectorItem } from '../selector'
import { computed } from 'vue'

export type PersonType = Pick<Person, 'id' | 'auth0Id' | 'name' | 'position' | 'photoUrl' | 'email'> &
    Partial<Pick<Person, 'isActive'>>

export const usePersons = () => {
    const {
        data: allPersons,
        isLoading: isLoadingAllPersons,
        isFetching: isFetchingAllPersons,
        isRefetching: isRefetchingAllPersons,
    } = useQuery({
        queryKey: ['listAllPersons'],
        queryFn: () => client.person.list.query(),
        queries: ['Person'],
        initialData: [],
    })
    const {
        data: nonExternalPersons,
        isLoading: isLoadingNonExternalPersons,
        isFetching: isFetchingNonExternalPersons,
        isRefetching: isRefetchingNonExternalPersons,
    } = useQuery({
        queryKey: ['listNonExternalPersons'],
        queryFn: () => client.person.listNonExternal.query(),
        queries: ['Person'],
        initialData: [],
    })

    function excludeOwner(ownerId?: string, persons?: PersonType[]) {
        return persons?.filter((person) => person.id !== ownerId) || []
    }

    function getAllPersonsIncludingInactive(persons?: PersonType[]) {
        const inactivePersons = persons?.filter(({ isActive }) => !isActive) || []
        return [...allPersons.value, ...inactivePersons]
    }

    const allPersonsForSelector = computed(() =>
        allPersons.value.map(
            (person) =>
                ({
                    id: person.id,
                    label: person.name,
                    email: person.email,
                    avatar: {
                        id: person.id,
                        photoUrl: person.photoUrl,
                    },
                }) satisfies SelectorItem
        )
    )

    const nonExternalPersonsForSelector = computed(() =>
        nonExternalPersons.value.map(
            (person) =>
                ({
                    id: person.id,
                    label: person.name,
                    email: person.email,
                    avatar: {
                        id: person.id,
                        photoUrl: person.photoUrl,
                    },
                }) satisfies SelectorItem
        )
    )

    return {
        allPersons,
        isLoadingAllPersons,
        isFetchingAllPersons,
        isRefetchingAllPersons,

        nonExternalPersons,
        isLoadingNonExternalPersons,
        isFetchingNonExternalPersons,
        isRefetchingNonExternalPersons,

        excludeOwner,
        getAllPersonsIncludingInactive,

        allPersonsForSelector,
        nonExternalPersonsForSelector,
    }
}
