<script setup lang="ts">
import { computed } from 'vue'
import DActionMenu, { type DMenuItem } from '@/components/DActionMenu.vue'
import type { Person, Entity } from '@prisma/client'
import { useCommsMenu } from '@/composables/commsMenu'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const route = useRoute()

const { t } = useI18n()

const props = defineProps<{
    people: Pick<Person, 'id' | 'email' | 'auth0Id' | 'name'>[]
    entity: Pick<Entity, 'label' | 'id'>
}>()

const { sendEmails, copyEmails, sendMessage } = useCommsMenu({ subject: props.entity.label })

const disabled = computed(() => !props.people?.length)

const nonExternalPersons = computed(() => props.people.filter((person) => person.auth0Id))
const hasAtLeastOneNonExternalPerson = computed(() => nonExternalPersons.value.length > 0)

// TODO: Remove when Activities are available on BL ONE-1348
const isBusinessLineageView = computed(() => {
    return route?.name === 'business-lineage-full-screen'
})

const menuItems = computed<DMenuItem[]>(() => [
    {
        label: t('components.CommsMenu.sendMessage'),
        icon: 'send',
        command: () => sendMessage(nonExternalPersons.value),
        visible: hasAtLeastOneNonExternalPerson.value && !isBusinessLineageView.value,
    },
    {
        label: t('components.CommsMenu.sendEmail', props.people?.length),
        icon: 'mail-share',
        command: () => sendEmails(props.people),
        disabled: disabled.value,
    },
    {
        label: t('components.CommsMenu.copyEmail', props.people?.length),
        icon: 'mail',
        command: () => copyEmails(props.people),
        disabled: disabled.value,
    },
])
</script>

<template>
    <div>
        <DActionMenu :menu-items="menuItems" icon="dots-vertical" type="ghost" />
    </div>
</template>
