import { useI18n } from 'vue-i18n'
import type { Person } from '@prisma/client'
import { useCustomToast } from '@/composables/toast'
import { useClipboard } from '@vueuse/core'
import { eventBus } from '@/plugins/eventBus'
import { useHeap } from '@/plugins/heap'

export function useCommsMenu({ subject }: { subject: string | undefined }) {
    const { copy: copyToClipboard } = useClipboard()
    const toast = useCustomToast()
    const { t } = useI18n()
    const heap = useHeap()

    function sendEmails(people: Pick<Person, 'email'>[]) {
        if (!people.length) return
        const emails = people.map((person) => person.email.toLowerCase()).join(';')
        if (!emails.length) return toast.showErrorToast(t('components.CommsMenu.sendErrorToast'))
        const emailSubject = subject ? encodeURIComponent(subject) : ''
        return window.open(`mailto:${emails}?subject=${emailSubject}`, '_blank')
    }

    async function copyEmails(people: Pick<Person, 'email'>[]) {
        if (!people.length) return
        const emails = people.map((person) => person.email.toLowerCase()).join(';')
        if (!emails.length) return toast.showErrorToast(t('components.CommsMenu.copyErrorToast'))
        await copyToClipboard(emails)
        toast.showSuccessToast(t('components.CommsMenu.copySuccessToast', people.length))
    }

    function sendMessage(persons: Pick<Person, 'id' | 'name'>[]) {
        heap.track('Send Message In Delight', { persons })
        eventBus.emit('send-message-to-members', { persons })
    }

    return { sendEmails, copyEmails, sendMessage }
}
