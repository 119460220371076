<script setup lang="ts">
import { computed } from 'vue'

import type { PassThrough } from '@primevue/core'
import { type DividerPassThroughOptions } from 'primevue/divider'

const props = withDefaults(
    defineProps<{
        color?: 'light' | 'dark'
        layout?: 'horizontal' | 'vertical'
    }>(),
    {
        color: 'light',
        layout: 'horizontal',
    }
)

// https://github.com/primefaces/primevue-tailwind/blob/0.9.0/presets/aura/divider/index.js
const dividerPT = computed<PassThrough<DividerPassThroughOptions>>(() => ({
    root: ({ props: dividerProps }) => ({
        class: [
            // Flex and Position
            'flex relative',
            { 'justify-center': props.layout == 'vertical' },
            { 'items-center': props.layout == 'vertical' },
            {
                'justify-start': dividerProps?.align == 'left' && props.layout == 'horizontal',
                'justify-center': dividerProps?.align == 'center' && props.layout == 'horizontal',
                'justify-end': dividerProps?.align == 'right' && props.layout == 'horizontal',
                'items-center': dividerProps?.align == 'top' && props.layout == 'vertical',
                'items-start': dividerProps?.align == 'center' && props.layout == 'vertical',
                'items-end': dividerProps?.align == 'bottom' && props.layout == 'vertical',
            },

            // Spacing
            {
                'my-2 mx-0 py-0 px-5': props.layout == 'horizontal',
                'mx-2 md:mx-5 py-5': props.layout == 'vertical',
            },

            // Size
            {
                'w-full': props.layout == 'horizontal',
                'min-h-full': props.layout == 'vertical',
            },

            // Before: Line
            'before:block',

            // Position
            {
                'before:absolute before:left-0 before:top-1/2': props.layout == 'horizontal',
                'before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2':
                    props.layout == 'vertical',
            },

            // Size
            {
                'before:w-full': props.layout == 'horizontal',
                'before:min-h-full': props.layout == 'vertical',
            },

            // Shape
            {
                'before:border-solid': dividerProps.type == 'solid',
                'before:border-dotted': dividerProps.type == 'dotted',
                'before:border-dashed': dividerProps.type == 'dashed',
            },

            // Color and Layout
            {
                'before:border-slate-200': props.color == 'light',
                'before:border-slate-300': props.color == 'dark',
            },

            // Layout
            {
                'before:border-t': props.layout == 'horizontal',
                'before:border-l': props.layout == 'vertical',
            },
        ],
    }),
    content: {
        class: [
            // Space and Position
            'px-1 z-10',

            // Color
            'bg-surface-0 dark:bg-surface-800',
        ],
    },
}))
</script>

<template>
    <PDivider :pt="dividerPT" :layout="layout" unstyled>
        <slot />
    </PDivider>
</template>
