<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import DDivider from './DDivider.vue'
import DIcon from './DIcon.vue'
import type { MenuItem } from 'primevue/menuitem'
import { QuillEditor } from '@vueup/vue-quill'

import type { TextEditorFormat, RangeStatic } from '@/composables/textEditor'
import DTextEditorDropdown from './DTextEditorDropdown.vue'

const props = withDefaults(
    defineProps<{
        editorId: string
        showCharCount?: boolean
        totalChars?: number
        maxChars?: number
        excludedFormats?: string[]
        quillInstance?: typeof QuillEditor
        selectionFormat?: string
        disabledHeadings?: boolean
        maxWidth: number
    }>(),
    {
        totalChars: 0,
        showCharCount: false,
        disabledHeadings: false,
    }
)

const emit = defineEmits<{
    (event: 'mention-click', value: '@' | '#'): void
    (event: 'set-format', value: { type: string; value: number | boolean; selection?: RangeStatic }): void
    (event: 'edit-link', value: Event): void
}>()

const includesFormat = (formats: TextEditorFormat | TextEditorFormat[]) => {
    if (Array.isArray(formats)) {
        return formats.some((format) => !props.excludedFormats?.includes(format))
    }
    return !props.excludedFormats?.includes(formats)
}
const selection = ref<{ index: number; length: number } | undefined>(undefined)

const { t } = useI18n()
const textSizeMenu: MenuItem[] = [
    {
        id: 'heading1',
        label: t('textEditor.heading1'),
        value: 1,
        class: 'ql-header',
    },
    {
        id: 'heading2',
        label: t('textEditor.heading2'),
        value: 2,
        class: 'ql-header',
    },
    {
        id: 'text',
        label: t('textEditor.text'),
        value: false,
    },
]

const defaultTextSize = computed(
    () => textSizeMenu.find((item) => item.id === props.selectionFormat) || textSizeMenu[2]
)
const selectedTextSize = ref<MenuItem | undefined>(defaultTextSize)
</script>
<template>
    <div v-if="editorId" :id="editorId" :style="{ 'max-width': `${maxWidth}px` }">
        <DTextEditorDropdown
            v-if="includesFormat(['heading']) && !disabledHeadings"
            :model-value="selectedTextSize"
            :options="textSizeMenu"
            @toggle-menu="selection = quillInstance?.getSelection()"
            @change="emit('set-format', { type: 'heading', value: $event.value, selection })"
        />

        <DDivider
            v-if="
                includesFormat(['underline', 'italic', 'bold', 'strike']) &&
                includesFormat(['heading']) &&
                !disabledHeadings
            "
            color="light"
            layout="vertical"
            class="!mx-1.5 h-8"
        />

        <span class="flex gap-x-1">
            <button
                v-if="includesFormat('bold')"
                v-tooltip.bottom="$t('textEditor.bold')"
                type="button"
                class="ql-bold"
            >
                <DIcon icon="bold" :exact-size="18" />
            </button>
            <button
                v-if="includesFormat('italic')"
                v-tooltip.bottom="$t('textEditor.italic')"
                type="button"
                class="ql-italic"
            >
                <DIcon icon="italic" :exact-size="18" />
            </button>
            <button
                v-if="includesFormat('underline')"
                v-tooltip.bottom="$t('textEditor.underline')"
                type="button"
                class="ql-underline"
            >
                <DIcon icon="underline" :exact-size="18" />
            </button>
            <button
                v-if="includesFormat('strike')"
                v-tooltip.bottom="$t('textEditor.strike')"
                type="button"
                class="ql-strike"
            >
                <DIcon icon="strikethrough" :exact-size="18" />
            </button>
        </span>

        <DDivider v-if="includesFormat('link')" color="light" layout="vertical" class="!mx-1.5 h-8" />

        <span>
            <button
                v-if="includesFormat('link')"
                v-tooltip.bottom="$t('textEditor.link')"
                type="button"
                :class="selectionFormat === 'link' ? 'ql-active' : ''"
                @click="emit('edit-link', $event)"
            >
                <DIcon icon="link" :exact-size="18" />
            </button>
        </span>

        <DDivider
            v-if="includesFormat(['bullet-list', 'ordered-list', 'task-list'])"
            color="light"
            layout="vertical"
            class="!mx-1.5 h-8"
        />

        <span class="flex gap-x-1">
            <button
                v-if="includesFormat('bullet-list')"
                v-tooltip.bottom="$t('textEditor.bulletList')"
                type="button"
                class="ql-list"
                value="bullet"
            >
                <DIcon icon="list" :exact-size="18" />
            </button>
            <button
                v-if="includesFormat('ordered-list')"
                v-tooltip.bottom="$t('textEditor.orderedList')"
                type="button"
                class="ql-list"
                value="ordered"
            >
                <DIcon icon="list-numbers" :exact-size="18" />
            </button>
            <button
                v-if="includesFormat('task-list')"
                v-tooltip.bottom="$t('textEditor.taskList')"
                type="button"
                class="ql-list"
                value="unchecked"
            >
                <DIcon icon="list-check" :exact-size="18" />
            </button>
        </span>

        <DDivider v-if="includesFormat(['mention', 'clean'])" color="light" layout="vertical" class="!mx-1.5 h-8" />

        <span class="flex gap-x-1">
            <button
                v-if="includesFormat('mention')"
                v-tooltip.bottom="$t('textEditor.mentionPerson')"
                type="button"
                @click="emit('mention-click', '@')"
            >
                <DIcon icon="at" :exact-size="18" />
            </button>
            <button
                v-if="includesFormat('mention')"
                v-tooltip.bottom="$t('textEditor.linkItem')"
                type="button"
                @click="emit('mention-click', '#')"
            >
                <DIcon icon="hash" :exact-size="18" />
            </button>
            <button
                v-if="includesFormat('clean')"
                v-tooltip.bottom="$t('textEditor.clearFormatting')"
                type="button"
                class="ql-clean"
            >
                <DIcon icon="clear-formatting" :exact-size="18" />
            </button>
            <!-- TODO: add copilot/lumo selector -->
        </span>

        <DDivider v-if="showCharCount && maxChars" color="light" layout="vertical" class="!mx-1.5 h-8" />

        <div
            v-if="showCharCount && maxChars"
            class="ml-auto mr-2 pl-1 whitespace-nowrap text-sm"
            :class="{
                'text-slate-600': totalChars <= maxChars,
                'text-red-600': totalChars > maxChars,
            }"
        >
            {{ totalChars }} / {{ maxChars }}
        </div>
    </div>
</template>
