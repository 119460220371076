<script lang="ts" setup>
import { computed } from 'vue'
import type { ProgressSpinnerPassThroughOptions } from 'primevue/progressspinner'
import type { PassThrough } from '@primevue/core'
import type { Size } from '@/composables/sizing'

const props = withDefaults(
    defineProps<{
        color?: string
        size?: Size
    }>(),
    {
        color: '#6366F1',
        size: 'lg',
    }
)

// NOTE: There is no such sizing in figma. Fix/Improve if needed.
const size = computed(() => {
    return {
        sm: '!h-4 !w-4',
        md: '!h-11 !w-11',
        lg: '!h-[100px] !w-[100px]',
    }[props.size]
})

const progressSpinnerPT = computed<PassThrough<ProgressSpinnerPassThroughOptions>>(() => ({
    spinner: { style: { animationDuration: '.5s' }, class: size.value },
    circle: { style: { stroke: props.color, strokeWidth: 3, animation: 'none' }, class: size.value },
    root: { class: size.value },
}))
</script>

<template>
    <PProgressSpinner :pt="progressSpinnerPT" />
</template>
