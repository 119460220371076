import { type RouteLocationNormalized, type NavigationGuardNext, onBeforeRouteLeave } from 'vue-router'
import { onBeforeUnmount, onBeforeMount, unref, type Ref, type MaybeRef, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDialog } from './dialogs'
import { useRouter } from 'vue-router'
import type { ConfirmationOptions } from 'primevue/confirmationoptions'
import { type EntityType } from '@prisma/client'
import { useEntityTypes } from './entityType'
import { useSavedView, type FavoriteSavedView } from '@/modules/saved-view/queries'
import { getVisibilityPrivateIcon } from '@/modules/saved-view/composables/visibility'
import { useFeatureToggles } from '@/composables/featureToggles'

export interface SideNavItem {
    id?: string
    to: string
    label: string
    icon: string
    iconRight?: string
    childNodes?: SideNavItem[]
    draggableChildren?: boolean
    onDraggableUpdate?: (items: (SideNavItem & { nodeData: unknown })[]) => void
    nodeData?: unknown
    shouldParentBeInactive?: 'onlyForDirectChildren' | boolean
}

export function useNavigation() {
    const { t } = useI18n()
    const { opportunityType, dataProductTypes } = useEntityTypes()
    const { listFavorites, reorderFavorites } = useSavedView()
    const isEnabledMissionControl = useFeatureToggles().missionControl

    const { data: _savedViews, dataUpdatedAt: listUpdatedAt } = listFavorites()
    const { mutate: reorderSavedViews, variables: reorderPayload, submittedAt: reorderSubmittedAt } = reorderFavorites()

    const savedViews = computed(() => {
        if (reorderSubmittedAt.value > listUpdatedAt.value && reorderPayload.value) {
            return reorderPayload.value.items.map(
                (item) => (item as unknown as { nodeData: FavoriteSavedView }).nodeData
            )
        }
        return _savedViews.value || []
    })

    const mainNavigation = computed<SideNavItem[]>(() => {
        const navItems: SideNavItem[] = [
            {
                to: '/inbox',
                label: t('inbox.title'),
                icon: 'inbox',
            },
        ]

        if (isEnabledMissionControl.value) {
            navItems.push({
                to: '/mission-control',
                label: t('missionControl.titles.missionControl'),
                icon: 'chart-arcs',
                shouldParentBeInactive: true,
                childNodes: [
                    {
                        to: '/mission-control/opportunity-report',
                        label: t('missionControl.titles.opportunityReport'),
                        icon: 'file-description',
                    },
                    {
                        to: '/mission-control/data-product-report',
                        label: t('missionControl.titles.dataProductReport'),
                        icon: 'file-description',
                    },
                ],
            })
        }

        navItems.push(
            {
                to: `/${opportunityType.value.routePath}`,
                label: opportunityType.value.labelPlural,
                icon: opportunityType.value.icon,
            },
            {
                to: '/data-product',
                label: t('entityTypes.dataProducts'),
                icon: 'squares',
                shouldParentBeInactive: true,
                childNodes: dataProductTypes.value.map((type) => ({
                    to: `/data-product/${type.routePath}`,
                    label: type.labelPlural,
                    icon: type.icon,
                })),
            },
            {
                to: '/my-views',
                label: t('savedViews.title'),
                icon: 'stack-2',
                shouldParentBeInactive: 'onlyForDirectChildren',
                draggableChildren: true,
                onDraggableUpdate: (items) => {
                    const payload = items.map(({ id, nodeData }, order) => {
                        if (!id) throw new Error('Saved view id is missing to set the order')
                        return { id, order, nodeData }
                    })
                    reorderSavedViews({ items: payload })
                },
                childNodes: savedViews.value.map((view) => ({
                    id: view.id,
                    to: `/my-views/${view.id}`,
                    label: view.label,
                    icon: view.icon,
                    iconRight: getVisibilityPrivateIcon(view.visibility),
                    nodeData: {
                        id: view.id,
                        label: view.label,
                        icon: view.icon,
                        visibility: view.visibility,
                    },
                })),
            }
        )

        return navItems
    })

    return {
        mainNavigation,
    }
}

export const useEntityTypeByRoute = () => {
    const router = useRouter()
    const { entityTypes, dataProductType } = useEntityTypes()

    return {
        getEntityTypeByRoute() {
            const currentPath = router.currentRoute.value.path
            // TODO: Fix. Will not find opportunity. ONE-1172
            const userDefined = entityTypes.value.find((type) =>
                currentPath.startsWith(`/data-product/${type.routePath}`)
            )
            const dataProductsGroup =
                currentPath === `/${dataProductType.value.routePath}` ? dataProductType.value : undefined

            return userDefined || dataProductsGroup
        },
    }
}

export interface TabItem {
    label: string
    routeName: string
    icon?: string
    content?: string
    active?: boolean
    disabled?: boolean
}

export const useTabMenu = () => {
    const router = useRouter()

    return {
        onTabMenuChange(tabs: TabItem[], { index }: { index: number }) {
            router.replace({ name: tabs[index].routeName })
        },
        setCurrentTab(tabs: TabItem[]) {
            const route = router.currentRoute.value
            const currentTab = tabs.find((tab) => tab.routeName === route.name)
            if (currentTab) currentTab.active = true
        },
    }
}

export const useUnsavedChanges = (hasUnsavedChanges: Ref<boolean>, options?: ConfirmationOptions) => {
    const { t } = useI18n()
    const confirm = useDialog()

    const preventNav = (event: BeforeUnloadEvent) => {
        if (!hasUnsavedChanges.value) return
        event.preventDefault()
        // Chrome requires returnValue to be set
        event.returnValue = options?.header ?? t('dialog.changes.header')
    }

    onBeforeMount(() => {
        // We use the native `beforeunload` event
        window.addEventListener('beforeunload', preventNav)
    })

    // Make sure to always clean up after yourself!
    onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', preventNav)
    })

    onBeforeRouteLeave(
        async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
            if (!hasUnsavedChanges.value) {
                next(true)
            } else {
                const result = await confirm.asyncRequire({
                    ...options,
                    header: options?.header ?? t('dialog.changes.header'),
                    message: options?.message ?? t('dialog.changes.message'),
                })
                next(result)
            }
        }
    )
}

/**
 *  This function is used to convert an entity type and an id to a route location.
 *  If no ID is provided, it will return the route location for the list page of the entity type.
 */
export function entityTypeToRoute(entityTypeRef: MaybeRef<Pick<EntityType, 'routePath'> | undefined>, id?: string) {
    const entityType = unref(entityTypeRef)

    if (!entityType?.routePath) return {}
    if (!id) return { name: `${entityType.routePath}-list` }
    return {
        name: `${entityType.routePath}-details`,
        params: { id },
    }
}
