<script setup lang="ts">
import { onMounted, ref, useTemplateRef } from 'vue'
import { z } from 'zod'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'

import DInput from './DInputText.vue'
import DButton from './DButton.vue'
import DDatePicker from './DDatePicker.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

import { MAX_LENGTH } from '@mindfuel/server/src/common/config'

export interface Item {
    id: string
    label: string
}

const props = withDefaults(
    defineProps<{
        id: string
        text?: string
        date?: Date
        selectedOptions?: Item[]
        selectableOptions?: Item[]
        placeholder?: string
        maxLength?: number
        hasDate?: boolean
        focusOnMounted?: boolean
        submitOnEnter?: boolean
        applyButtonIcon?: string
        applyButtonTooltipText?: string
        resetButtonIcon?: string
        resetButtonTooltipText?: string
        selectableOptionsText?: string
    }>(),
    {
        hasDate: false,
        submitOnEnter: true,
    }
)

export interface InlineMicroForm {
    id: string
    text: string
    date?: Date
    options?: Item[]
}

const emit = defineEmits<{
    (name: 'submit', value: InlineMicroForm): void
    (name: 'reset'): void
}>()

const dateVisible = ref(!!props?.date)
const input = useTemplateRef('input')

onMounted(() => {
    if (props.focusOnMounted) {
        input.value?.focus()
    }
})

const { defineField, handleSubmit, handleReset, meta, errors } = useForm({
    initialValues: {
        text: props.text ?? '',
        date: props.date,
        id: props.id,
        options: props.selectedOptions,
    },
    validationSchema: toTypedSchema(
        z.object({
            text: z
                .string()
                .trim()
                .min(1)
                .max(props.maxLength ?? MAX_LENGTH.MEDIUM_LABEL),
            date: z.date().optional(),
            id: z.string(),
            options: z.object({ id: z.string(), label: z.string() }).array().optional(),
        })
    ),
})
const [textValue] = defineField('text')
const [dateValue] = defineField('date')
const [optionsValue] = defineField('options')

const openCalendar = () => {
    dateVisible.value = true
    setTimeout(() => {
        document.getElementById(props.id + '-date')?.focus()
    }, 0)
}

const onSubmit = handleSubmit((values) => {
    emit('submit', values)
})

const onReset = () => {
    handleReset()
    dateVisible.value = !!props?.date
    emit('reset')
}
</script>

<template>
    <div class="flex items-center justify-between" @keyup.enter="submitOnEnter && onSubmit()">
        <DInput
            :id="id + '-text'"
            ref="input"
            v-model="textValue"
            ghost
            size="sm"
            :placeholder="placeholder ?? $t('components.InlineMicroForm.placeholder')"
            :error="!!errors.text"
            class="w-full"
            testid="inline-micro-form-input"
        />
        <DDatePicker
            v-if="hasDate && dateVisible"
            :id="id + '-date'"
            v-model="dateValue"
            size="sm"
            ghost
            :placeholder="$t('milestones.propertyPanel.datePlaceholder')"
            class="ml-2"
            :error="!!errors.date"
        />
        <div class="ml-2 flex items-center space-x-1">
            <slot name="buttons" />
            <DButton
                v-if="hasDate && !dateVisible"
                v-tooltip.bottom="$t('components.InlineMicroForm.date')"
                :aria-label="$t('components.InlineMicroForm.date')"
                type="ghost"
                icon="calendar"
                @click="openCalendar"
            />
            <DSelectorGeneric
                v-if="selectableOptions"
                v-model="optionsValue"
                :options="selectableOptions"
                multiple
                :filter-placeholder="selectableOptionsText"
            >
                <DButton
                    v-tooltip.bottom="selectableOptionsText"
                    type="ghost"
                    icon="gps"
                    :aria-label="selectableOptionsText"
                />
            </DSelectorGeneric>
            <DButton
                v-tooltip.bottom="applyButtonTooltipText ?? $t('components.InlineMicroForm.apply')"
                :aria-label="applyButtonTooltipText ?? $t('components.InlineMicroForm.apply')"
                type="ghost"
                :icon="applyButtonIcon ?? 'check'"
                :disabled="!meta.valid"
                data-testid="inline-micro-form-apply-button"
                @click="onSubmit"
            />
            <DButton
                v-tooltip.bottom="resetButtonTooltipText ?? $t('components.InlineMicroForm.cancel')"
                :aria-label="resetButtonTooltipText ?? $t('components.InlineMicroForm.cancel')"
                type="ghost"
                :icon="resetButtonIcon ?? 'x'"
                @click="onReset"
            />
        </div>
    </div>
</template>
