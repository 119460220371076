<script setup lang="ts">
import { computed, useTemplateRef, type ComponentPublicInstance } from 'vue'
import type { DatePickerPassThroughOptions } from 'primevue/datepicker'
import type { PassThrough } from '@primevue/core'
import type { Size } from '@/composables/sizing'
import DLabel from './DLabel.vue'

const props = withDefaults(
    defineProps<{
        id: string
        modelValue?: null | Date
        label?: string
        placeholder?: string
        error?: string | boolean
        size?: Size
        disabled?: boolean
        focusOnMounted?: boolean
        blurOnEnter?: boolean
        ghost?: boolean
        mediumText?: boolean
        cursorPointer?: boolean
        lightTextColor?: boolean
    }>(),
    {
        disabled: false,
        size: 'md',
    }
)
const emit = defineEmits(['update:modelValue', 'date-select'])

defineExpose({
    focus() {
        focusInput()
    },
    blur() {
        blurInput()
    },
})

const datePicker = useTemplateRef<ComponentPublicInstance>('datePicker')

const datePickerPT = computed<PassThrough<DatePickerPassThroughOptions>>(() => ({
    pcInputText: {
        root: {
            class: [
                {
                    '!p-0': props.ghost,
                    '!px-1': !props.ghost && props.size === 'sm',
                    '!px-2': !props.ghost && props.size === 'md',
                    '!px-3': !props.ghost && props.size === 'lg',
                },
                {
                    'py-2': props.size === 'sm',
                    'py-3': props.size === 'md',
                    'py-4': props.size === 'lg',
                },
                {
                    '!text-sm !leading-6 !h-6': props.size === 'sm',
                    '!text-base !leading-7': props.size === 'md',
                    '!text-base !leading-8': props.size === 'lg',
                },
                {
                    '!font-medium': props.mediumText,
                },
                {
                    '!placeholder-slate-400': props.placeholder,
                },
                {
                    '!border-none': props.ghost,
                    '!bg-rose-50': props.ghost && props.error,
                    '!border-rose-500': !props.ghost && props.error,
                    '!border-slate-300': !props.ghost && !props.error,
                    '!text-slate-500': props.lightTextColor,
                },
                {
                    'border-slate-300 text-slate-400 bg-slate-100': props.disabled && !props.ghost,
                },
                {
                    'focus:outline-none border focus:border-primarySky-300': !props.disabled && !props.ghost,
                },
            ],
        },
    },
}))

function focusInput() {
    if (!datePicker?.value) return
    datePicker.value.$el.querySelector('input')?.focus()
}
function blurInput() {
    if (!datePicker?.value) return
    datePicker.value.$el.querySelector('input')?.blur()
}
</script>

<template>
    <div class="flex flex-col">
        <DLabel v-if="label" :for-id="id" :label="label" padding-bottom />
        <PDatepicker
            ref="datePicker"
            :input-id="id"
            :model-value="modelValue"
            date-format="dd.mm.yy"
            :placeholder="placeholder"
            :disabled="disabled"
            :pt="datePickerPT"
            @update:model-value="emit('update:modelValue', $event)"
            @date-select="$emit('date-select')"
        />
        <div v-if="typeof error === 'string'" class="text-xs font-normal text-rose-500">{{ error }}</div>
    </div>
</template>
