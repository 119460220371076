<script setup lang="ts">
import { computed } from 'vue'

import type { PassThrough } from '@primevue/core'
import type { DialogProps, DialogPassThroughOptions } from 'primevue/dialog'

import DButton from './DButton.vue'
import MarkerButton from './MarkerButton.vue'

const props = withDefaults(
    defineProps<{
        header?: string
        content?: string
        confirmButtonLabel?: string
        cancelButtonLabel?: string
        modelValue?: boolean
        modal?: boolean
        type?: 'warn' | 'action'
        position?: DialogProps['position']
        containerClass?: string
        width?: string
        height?: string
        closable?: boolean
    }>(),
    {
        modal: false,
        type: 'action',
        closable: false,
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue'): void
    (name: 'confirm'): void
}>()

const dialogPT = computed<PassThrough<DialogPassThroughOptions>>(() => ({
    root: {
        class: [
            'd-alert-dialog rounded-lg shadow-lg border-0 transform scale-100 m-0 bg-white px-6 py-4',
            props.containerClass,
            props.width ?? 'min-w-[360px] max-w-[620px]',
            props.height ?? 'max-h-90',
        ],
    },
    mask: ({ props }) => ({
        class: ['d-alert-dialog-mask z-alert transition duration-200', { 'bg-black/15 backdrop-blur': props.modal }],
    }),
    transition: ({ props }) => {
        return props.position === 'top'
            ? {
                  enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                  enterActiveClass: 'transition-all duration-200 ease-out',
                  leaveActiveClass: 'transition-all duration-200 ease-out',
                  leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
              }
            : props.position === 'bottom'
              ? {
                    enterFromClass: 'opacity-0 scale-75 translate-y-full',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
                }
              : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
                ? {
                      enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                      enterActiveClass: 'transition-all duration-200 ease-out',
                      leaveActiveClass: 'transition-all duration-200 ease-out',
                      leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
                  }
                : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
                  ? {
                        enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass:
                            'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                    }
                  : {
                        enterFromClass: 'opacity-0 scale-75',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass: 'opacity-0 scale-75',
                    }
    },
}))
</script>

<template>
    <PDialog
        :modal="modal"
        :closable="closable || !!cancelButtonLabel"
        unstyled
        :close-on-escape="closable || !!cancelButtonLabel"
        :pt="dialogPT"
        :auto-z-index="false"
        :position="position"
        data-testid="alert-dialog"
        @update-visible="emit('update:modelValue')"
    >
        <template #container="{ closeCallback }">
            <div class="flex cursor-default flex-col gap-y-3 overflow-y-hidden">
                <div
                    data-testid="alert-dialog-heading"
                    class="line-clamp-2 text-lg font-semibold min-h-6 text-slate-700"
                >
                    {{ header }}
                </div>
                <slot name="content">
                    <div class="max-h-80 overflow-auto text-base text-black">{{ content }}</div>
                </slot>
                <slot name="action" :close="closeCallback">
                    <div class="flex justify-end gap-x-1">
                        <DButton :label="cancelButtonLabel || $t('cancel')" size="lg" @click="closeCallback" />
                        <DButton
                            data-testid="alert-dialog-confirm-button"
                            :label="confirmButtonLabel || $t('confirm')"
                            size="lg"
                            :type="type === 'warn' ? 'danger' : 'confirm'"
                            @click="emit('confirm')"
                        />
                    </div>
                </slot>
            </div>
            <Teleport to="body">
                <MarkerButton />
            </Teleport>
        </template>
    </PDialog>
</template>
